import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { typography } from 'styled-system';
import { themeGet } from '../../../utils';

import { Box } from '../../atoms';

import iconDont from './dont.svg';
import iconDo from './do.svg';

const color = ({ status }) => themeGet(`colors.${status === 'good' ? 'success' : 'error'}`);

const Icon = styled(Box)`
  width: 32px;
  height: 32px;
  margin-right: 20px;
  background: ${color};
  border-radius: 50%;

  img {
    vertical-align: baseline;
  }
`;

const StyledFootNote = styled(Box)`
  display: flex;
  align-items: center;
  ${typography};
  border-bottom: 1px solid ${color};
`;

const FootNote = (props) => {
  const { status } = props;
  return (
    <StyledFootNote {...props}>
      <Icon status={status}>
        <img src={status === 'good' ? iconDo : iconDont} alt="" />
      </Icon>
      <span>{status === 'good' ? 'Do' : "Don't"}</span>
    </StyledFootNote>
  );
};

FootNote.propTypes = {
  status: PropTypes.oneOf(['good', 'bad']),
  fontSize: PropTypes.string,
  m: PropTypes.string,
  py: PropTypes.string,
};

FootNote.defaultProps = {
  status: 'good',
  fontSize: 'm',
  m: '16px 0 20px',
  py: 's',
};

export default FootNote;
