import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import Inner from '../../components/templates/Inner';
import { Divider, Box, Flex, Text } from '../../components/atoms';
import FootNote from '../../components/molecules/FootNote';
import img01 from '../../assets/img/design-tips/typography/01.png';
import img02 from '../../assets/img/design-tips/typography/02.png';
import img03 from '../../assets/img/design-tips/typography/03.png';
import img04 from '../../assets/img/design-tips/typography/04.png';
import img05 from '../../assets/img/design-tips/typography/05.png';
import img06 from '../../assets/img/design-tips/typography/06.png';
import img07 from '../../assets/img/design-tips/typography/07.png';
import img08 from '../../assets/img/design-tips/typography/08.png';
import img09 from '../../assets/img/design-tips/typography/09.png';
import img10 from '../../assets/img/design-tips/typography/10.png';
import img11 from '../../assets/img/design-tips/typography/11.png';
import img12 from '../../assets/img/design-tips/typography/12.png';
import img13 from '../../assets/img/design-tips/typography/13.png';
import { designTips as nav } from '../../nav';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <Inner nav={nav} title="Typography" mdxType="Inner">
      <h3>{`Typography`}</h3>
  <Text maxWidth={560} mdxType="Text">
    Main application part that communicates with the user. We sort the information from big to small.
  </Text>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Simplify navigation`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Build the hierarchy to allow the user to navigate and consume content with ease.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={392} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img01} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Use font size and weight to emphasise hierarchy.</Box>
    </Box>
    <Box width={392} mdxType="Box">
      <Box mdxType="Box"><img src={img02} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">No emphasis, hard to navigate through the content.</Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Help them read your copy`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Readable font size is imperative. Recommended size for reading is 16-17pt. Altough it may vary according to the font and device type.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={392} mr={152} mdxType="Box">
      <Box mdxType="Box"><img src={img04} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Readable without extra effort.</Box>
    </Box>
    <Box width={392} mdxType="Box">
      <Box mdxType="Box"><img src={img03} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Small font, probably hard to read.</Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Font size: line height = 1:1.5`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Font line height is proportional to its size. Recommended ratio for the paragraph is 1:1.5. Exception: headlines that need smaller height line, the ratio is approximately at 1:1.24.
  </Text>
  <Flex my="xxl" mdxType="Flex">
    <Box width={392} mr={152} mdxType="Box">
      <Box height="156px" mdxType="Box"><img src={img06} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mb="50px" mdxType="Box">Too tight</Box>
      <Box mdxType="Box"><img src={img07} alt="" className="img-fluid" /></Box>
      <FootNote status="good" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Balanced 1:1.5 line spacing</Box>
    </Box>
    <Box width={392} mdxType="Box">
      <Box mdxType="Box"><img src={img05} alt="" className="img-fluid" /></Box>
      <FootNote status="bad" mdxType="FootNote" />
      <Box fontSize="m" mdxType="Box">Too wide</Box>
    </Box>
  </Flex>
  <Divider my={64} mdxType="Divider" />
      <h4>{`40-60 characters line length`}</h4>
  <Text maxWidth={560} mdxType="Text">
    It is good to keep the text line length around 40-60 characters. In areas with broader line lengt paragraphs may contain up to 120 characters. In this case, you need bigger line spacing.
  </Text>
  <Box maxWidth={828} mb="30px" mdxType="Box"><img src={img08} alt="" className="img-fluid" /></Box>
      <p>{`  20-40 characters when lines are shorter.`}</p>
  <Box maxWidth={714} mdxType="Box"><img src={img09} alt="" className="img-fluid" /></Box>
  <Divider my={64} mdxType="Divider" />
      <h4>{`Don’t brake the rectangle`}</h4>
  <Text maxWidth={560} mdxType="Text">
    Text blocks must contain an optimal number of lines and balanced width.
    Imagine that you put the heading and text inside a rectangle.
  </Text>
  <Box maxWidth={840} mdxType="Box"><img src={img10} alt="" className="img-fluid" /></Box>
  <Box width={420} mb="xxl" mdxType="Box">
    <FootNote status="bad" mdxType="FootNote" />
    <Box fontSize="m" mdxType="Box">Paragraphs are too wide compared to headline. Line length rule is not respected.</Box>
  </Box>
  <Box maxWidth={840} mdxType="Box"><img src={img11} alt="" className="img-fluid" /></Box>
  <Box width={420} mb="xxl" mdxType="Box">
    <FootNote status="good" mdxType="FootNote" />
    <Box fontSize="m" mdxType="Box">Proper line length makes the paragraph look pulled together.</Box>
  </Box>
  <Box maxWidth={840} mdxType="Box"><img src={img12} alt="" className="img-fluid" /></Box>
  <Box width={420} mb="xxl" mdxType="Box">
    <FootNote status="bad" mdxType="FootNote" />
    <Box fontSize="m" mdxType="Box">Don’t make the headlines too long. They take much space and are hard to read. </Box>
  </Box>
  <Box maxWidth={840} mdxType="Box"><img src={img13} alt="" className="img-fluid" /></Box>
  <Box width={420} mdxType="Box">
    <FootNote status="good" mdxType="FootNote" />
    <Box fontSize="m" mdxType="Box">Limit the line length for headlines. Perfect headline length is 6 words or less. Try to keep the main thing the main thing.</Box>
  </Box>
    </Inner>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      